import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddUserModal = ({ onSaveUser, onClose, isEditing, selectedUser }) => {
  const [newUser, setNewUser] = useState({
    cover: null,
    name: '',
    email: '',
    phone: '',
    password: ''
  });

  useEffect(() => {
    if (isEditing && selectedUser) {
      setNewUser({
        cover: selectedUser.cover || null,
        name: selectedUser.name || '',
        email: selectedUser.email || '',
        phone: selectedUser.phone || '',
        password: '' // Leave password empty for security reasons
      });
    }
  }, [isEditing, selectedUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleFileChange = (e) => {
    setNewUser((prevUser) => ({ ...prevUser, cover: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', newUser.name);
    formData.append('email', newUser.email);
    formData.append('phone', newUser.phone);
    if (newUser.password) {
      formData.append('password', newUser.password);
    }
    // Uncomment the line below if you want to send the cover image
    // formData.append('cover', newUser.cover);

    try {
      if (isEditing) {
        // Edit existing user
        await axios.put(`https://10min.in/api/user/update/${selectedUser._id}`, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } else {
        // Create new user
        await axios.post('https://10min.in/api/user/signup', formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

      onSaveUser(); // Call the callback function to refresh the user list
      onClose();   // Close the modal
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-4 w-11/12 max-w-lg overflow-auto max-h-full">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">
          {isEditing ? 'Edit User' : 'Add New User'}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700">Name</label>
            <input
              type="text"
              name="name"
              value={newUser.name}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              value={newUser.email}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Phone</label>
            <input
              type="text"
              name="phone"
              value={newUser.phone}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <input
              type="password"
              name="password"
              value={newUser.password}
              onChange={handleChange}
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-gray-700"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
            >
              {isEditing ? 'Save Changes' : 'Add'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUserModal;
