import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api';

const AddAddress = ({ closeModal, billingAddress }) => {
  const defaultCenter = {
    lat: 28.7041, // Latitude for New Delhi
    lng: 77.1025  // Longitude for New Delhi
  };

  const [addressType, setAddressType] = useState('Home');
  const [flatNumber, setFlatNumber] = useState('');
  const [floor, setFloor] = useState('');
  const [locality, setLocality] = useState('');
  const [landmark, setLandmark] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [lat, setLat] = useState(defaultCenter.lat);
  const [lng, setLng] = useState(defaultCenter.lng);
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);

  const autocompleteRef = useRef(null);
  const addressId = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });

  useEffect(() => {
    if (billingAddress && billingAddress.length > 0) {
      setAddressType(billingAddress[0].addressType || 'Home');
      setFlatNumber(billingAddress[0].flatNumber || '');
      setFloor(billingAddress[0].floor || '');
      setLocality(billingAddress[0].locality || '');
      setLandmark(billingAddress[0].landmark || '');
      setName(billingAddress[0].name || '');
      setPhone(billingAddress[0].phone || '');
      setLat(billingAddress[0].lat || defaultCenter.lat);
      setLng(billingAddress[0].lng || defaultCenter.lng);
      setMarkerPosition({
        lat: billingAddress[0].lat || defaultCenter.lat,
        lng: billingAddress[0].lng || defaultCenter.lng
      });
    }
  }, [billingAddress]);

  const onMarkerDragEnd = async (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    setMarkerPosition({ lat: newLat, lng: newLng });
    setLat(newLat);
    setLng(newLng);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newLat},${newLng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );
      const data = await response.json();
      if (data.results[0]) {
        setLocality(data.results[0].formatted_address);
      }
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };

  const onPlaceSelected = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const newLat = place.geometry.location.lat();
      const newLng = place.geometry.location.lng();
      setMarkerPosition({ lat: newLat, lng: newLng });
      setLat(newLat);
      setLng(newLng);
      setLocality(place.formatted_address);
    }
  };

  const relocateCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setMarkerPosition({ lat: latitude, lng: longitude });
        setLat(latitude);
        setLng(longitude);
        triggerMarkerDragEvent(latitude, longitude);
      },
      (error) => {
        console.error('Error getting location:', error);
      }
    );
  };

  const triggerMarkerDragEvent = (lat, lng) => {
    const fakeEvent = {
      latLng: {
        lat: () => lat,
        lng: () => lng,
      },
    };
    onMarkerDragEnd(fakeEvent);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMarkerPosition({ lat: latitude, lng: longitude });
          triggerMarkerDragEvent(latitude, longitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const addressData = {
      userId: localStorage.getItem('userId'),
      addressType,
      flatNumber,
      floor,
      locality,
      landmark,
      name,
      phone,
      lat,
      lng
    };
  
    const url = billingAddress && billingAddress.length > 0
      ? `https://10min.in/api/address/update/${localStorage.getItem('userId')}/${billingAddress[0]._id}` // Update API
      : `https://10min.in/api/address/add`; // Add API
  
    const method = billingAddress && billingAddress.length > 0 ? 'PUT' : 'POST'; // PUT for update, POST for add
  
    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(addressData)
      });
  
      if (response.ok) {
        const result = await response.json();
        console.log(billingAddress && billingAddress.length > 0 ? 'Address updated:' : 'Address added:', result);
        closeModal();
      } else {
        console.error('Failed to process address');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 overflow-auto">
      <div style={{
        height:'150vh'
      }} className="bg-white rounded-lg shadow-lg w-11/12 md:w-2/3 p-6 relative overflow-auto">
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={closeModal}
        >
          &times;
        </button>
        <h2 className="text-xl font-bold">Update Address</h2>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2 h-48 md:h-auto">
            {isLoaded ? (
              <GoogleMap
                center={markerPosition}
                zoom={12}
                gestureHandling="greedy"
                disableDefaultUI
                mapContainerStyle={{ width: '100%', height: '400px' }}
              >
                <MarkerF
                  position={markerPosition}
                  draggable
                  onDragEnd={onMarkerDragEnd}
                />
              </GoogleMap>
            ) : (
              <p>Loading map...</p>
            )}
            {isLoaded && (
              <button
                className="bg-green-500 text-white w-full py-2 rounded-md mt-4"
                onClick={relocateCurrentLocation}
              >
                Go to current location
              </button>
            )}
<br />
            <p style={{color:'red',    textAlign: 'center',
    marginTop: '2rem'}}>Move the Cursor to Set the Location</p>
          </div>
          <div className="w-full md:w-1/2 md:ml-4 mt-96 md:mt-0">
            {isLoaded && (
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Save address as *
                  </label>
                  <div className="flex space-x-2">
                    {['Home', 'Work', 'Hotel', 'Other'].map(type => (
                      <button
                        type="button"
                        key={type}
                        className={`py-2 px-4 rounded ${addressType === type ? 'bg-gray-400' : 'bg-gray-200'}`}
                        onClick={() => setAddressType(type)}
                      >
                        {type}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Flat / House no / Building name *
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border rounded"
                    value={flatNumber}
                    onChange={(e) => setFlatNumber(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Floor (optional)
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border rounded"
                    value={floor}
                    onChange={(e) => setFloor(e.target.value)}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-bold mb-2">
                    Locality / Area / Street *
                  </label>
                  <Autocomplete
                    onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                    onPlaceChanged={onPlaceSelected}
                  >
                    <input
                      type="text"
                      className="w-full px-3 py-2 border rounded"
                      value={locality}
                      onChange={(e) => setLocality(e.target.value)}
                      required
                    />
                  </Autocomplete>
                </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Landmark (optional)
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded"
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Contact Name *
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Contact Number *
                </label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border rounded"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-green-500 text-white w-full py-2 rounded-md"
              >
                Save Address
              </button>
            </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAddress;
